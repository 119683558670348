import React, { useState, useEffect, useContext, FC } from 'react';
import { AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area, ReferenceLine } from 'recharts';
import moment from 'moment-timezone';
import { curveLinear } from 'd3-shape';

import styles from '../chart.module.scss';
import { ChartToolTip } from '../index';
import { getPatientTimezone, PatientContext } from '../../../../contexts/PatientContext';
import { getTicksH, getTicksDD } from '../utlity';
import { getStressScores } from '../../../../services/api/chart';
import { getCurrentStressScore } from '../../../../services/api/firestore';
import { GRAPH_REVERSE_TOOLTIP_COORDINATE, CHARTS_SIZES } from '../../../../utils/constants';
import { Tooltip as InfoTooltip } from '../../tooltip';
import InfoBox from './info-box';
import firebase from 'firebase/app';

type Props = {
  start: string;
  end: string;
  urData?: { ts: string; value: number }[];
  isPdf?: boolean;
  reference?: number;
};

export const StressChart: FC<Props> = ({ start, end, urData, isPdf, reference }): JSX.Element => {
  const patientTimezone = getPatientTimezone();
  const [data, setData] = useState<Array<{ ts: number; value: number }>>([]);
  const { patient } = useContext(PatientContext);
  const GRAPH_WIDTH_PX = urData ? CHARTS_SIZES.UR_WIDTH : CHARTS_SIZES.CDP_WIDTH;
  const GRAPH_HEIGHT_PX = urData ? CHARTS_SIZES.UR_HEIGHT : CHARTS_SIZES.CDP_HEIGHT;
  let unsubscribeStressScore: () => void = () => {};

  const parseStressScores = () => {
    const parsedData = urData?.map(entry => ({
      value: entry.value,
      ts: moment(entry.ts).tz(patientTimezone).unix() * 1000,
    }));

    setData(parsedData!);
  };

  const getAndParseScores = async () => {
    try {
      const { data } = await getStressScores(patient!.id, Math.floor(GRAPH_WIDTH_PX / 4), {
        startDate: moment(start).format('YYYY-MM-DD'),
        endDate: moment(end).tz(patientTimezone).format('YYYY-MM-DD'),
        timezone: patientTimezone,
      });
      return (
        data?.points?.map(p => {
          return { ...p, ts: moment.utc(p.ts).tz(patientTimezone).unix() * 1000 };
        }) || []
      )
    } catch (err) {
      console.log(err)
    }
  };

  const getStressScoresAction = async patient => {
    try {
      let points = await getAndParseScores();
      unsubscribeStressScore = getCurrentStressScore(patient.firebaseUid!, {
        next: async (Snapshot: firebase.firestore.DocumentSnapshot) => {
          if (Snapshot) {
            const current = Snapshot.data();
            if (current?.score && current?.timestamp) {
              const { score, timestamp } = current;
              points = await getAndParseScores();
              const ts = moment(timestamp.seconds * 1000)
                .tz(patientTimezone)
                .format();
              // Need to have the points ordered by date so later I can easily get the latest sleep entry and compare it to the firebase current
              // if the firebase current is the same or earlier?than the last sleep entry, ignore it.
              const orderedPoints = points?.slice(0).sort((a, b) => moment(a.ts).unix() - moment(b.ts).unix());
              if (
                ts >= start &&
                ts <= end &&
                moment(timestamp.seconds * 1000).isAfter(orderedPoints?.slice(0).pop()?.ts)
              ) {
                setData(prevState => [
                  ...(points?.length ? points : prevState),
                  {
                    ts:
                      moment(timestamp.seconds * 1000)
                        .tz(patientTimezone)
                        .unix() * 1000,
                    value: score,
                  },
                ]);
              } else if (points?.length) {
                setData(points);
              }
              points = [];
            } else {
              if (points){ setData(points)};
            }
          }
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (patient) {
      if (!urData) {
        getStressScoresAction(patient);
      } else {
        parseStressScores();
      }
    }

    return (): void => {
      unsubscribeStressScore();
    };
  }, [patient, start, end]);

  const YAxisFormatter = ({
    x,
    y,
    payload,
  }: {
    x: number;
    y: number;
    payload: { coordinate: number; value: number; offset: number };
  }): JSX.Element => {
    const { value } = payload;
    if (!y || !x) {
      return <text />;
    }
    if (value === 0 && !urData) {
      return (
        <text
          fontSize='10'
          x={x - 36}
          y={y}
          textAnchor='start'
          color='#444444'
          fontWeight='normal'
          fontFamily='sans-serif'
        >
          Dec. {value}
        </text>
      );
    }
    if (value === 100 && !urData) {
      return (
        <text
          fontSize='10'
          x={x - 42}
          y={y + 7}
          textAnchor='start'
          color='#444444'
          fontWeight='normal'
          fontFamily='sans-serif'
        >
          Inc. {value}
        </text>
      );
    }
    return (
      <text
        fontSize='10'
        x={x - 7}
        y={y + 4}
        textAnchor='end'
        color='#444444'
        fontWeight='normal'
        fontFamily='sans-serif'
      >
        {value}
      </text>
    );
  };

  const customToolTip = ({
    active,
    coordinate,
    payload,
    label,
  }: {
    active: boolean;
    coordinate: { x: number; y: number };
    label: number;
    payload: Array<any>;
  }): JSX.Element | null => {
    if (!active) {
      return null;
    }
    const reverse = coordinate.x > GRAPH_REVERSE_TOOLTIP_COORDINATE;
    const yValue = payload ? payload[0]?.payload?.value : 'Unable to retrieve value';
    return <ChartToolTip date={label} title='STRESS' value={yValue} reverse={reverse} />;
  };

  return (
    <div id={`stressChart${isPdf ? '__pdf' : ''}`} style={isPdf ? { display: 'none' } : {}}>
      <div className={styles.chartHeader}>
        <span className={styles.chartTitle}>STRESS</span>
        {!urData && (
          <InfoTooltip baseStyles={styles.infoTooltip} type='left' background='#F5F6FA'>
            <InfoBox />
          </InfoTooltip>
        )}
      </div>
      <AreaChart
        // width={isPdf ? CHARTS_SIZES.UR_PDF_WIDTH : GRAPH_WIDTH_PX}
        // height={isPdf ? CHARTS_SIZES.UR_PDF_HEIGHT : GRAPH_HEIGHT_PX}
        width={CHARTS_SIZES.CDP_WIDTH}
        height={CHARTS_SIZES.CDP_HEIGHT + 30}
        data={data}
      >
        <defs>
          <linearGradient id='d1' x1={0} y1={0} x2={0} y2={1}>
            <stop offset='3%' stopColor='#417EB9' stopOpacity={0.8} />
            <stop offset='97%' stopColor='rgba(65, 126, 185, 0.2)' stopOpacity={0} />
          </linearGradient>
        </defs>
        {!urData && (
          <XAxis
            xAxisId={0}
            dx={2}
            style={{ fontSize: '9', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={false}
            tickSize={16}
            tickFormatter={(): string => ''}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksH(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!urData && (
          <XAxis
            xAxisId={0}
            dy={-18}
            dx={0}
            style={{
              fontSize: '11',
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              color: '#000000',
            }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(): string => ''}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!urData && (
          <XAxis
            xAxisId={0}
            dy={-34}
            dx={0}
            style={{ fontSize: '11', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(): string => ''}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!!urData && (
          <XAxis
            xAxisId={0}
            dy={-10}
            dx={2}
            style={{ fontSize: '9', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={true}
            tickSize={16}
            tickFormatter={(unixTime): string => moment.utc(unixTime).tz(patientTimezone).format('hA')}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksH(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!!urData && (
          <XAxis
            xAxisId={2}
            dy={-18}
            dx={0}
            style={{
              fontSize: '11',
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              color: '#000000',
            }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(unixTime): string => moment.utc(unixTime).format('ddd')}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!!urData && (
          <XAxis
            xAxisId={3}
            dy={-34}
            dx={0}
            style={{ fontSize: '11', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(unixTime): string => moment.utc(unixTime).format('M/D')}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!urData && (
          <XAxis
            xAxisId={4}
            dy={-25}
            dx={5}
            tickLine={false}
            axisLine={false}
            tickFormatter={(unixTime): string => {
             return  moment.utc(unixTime).format('M/D')
            }}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={1}
            // hide={title?.includes('sleep')}
          />
        )}
        {!!urData && reference && <ReferenceLine y={reference} stroke='green' strokeWidth={1.2} />}
        <YAxis tick={YAxisFormatter} interval={0} tickSize={0} ticks={[100, 75, 50, 25, 0]} />
        <CartesianGrid stroke='#A3A6B3' />
        {!urData && <Tooltip content={customToolTip} allowEscapeViewBox={{ x: false, y: true }} />}
        <Area type={curveLinear} dataKey='value' stroke='#417EB9' fill='url(#d1)' strokeWidth='2' />
      </AreaChart>
    </div>
  );
};
