import { MoodChart, StressChart } from 'components/common/chart';
import VitalsContainer from 'components/common/chart/vitals/vitals-container';
import { DateRange } from 'components/common/date-picker/date-range-picker';
import { FunctionComponent, useEffect } from 'react';
import SleepChartSection from './tinnitusSleepCharts/SleepCharts';
import SurveysChartContainer from 'components/common/chart/surveys/surveys-chart-container';
import { getPatientTimezone } from 'contexts/PatientContext';
import moment from 'moment-timezone';

type Props = {
  dateRangeFilter: DateRange;
};

const ChartsPage: FunctionComponent<Props> = ({ dateRangeFilter }) => {
  const { startDate, endDate } = dateRangeFilter;
  const patientTimezone = getPatientTimezone();
  const start = moment(startDate).tz(patientTimezone).format();
  const end = moment(endDate).tz(patientTimezone).format();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: '50px 10px 10px 10px',
        overflow: 'scroll',
        height: '600px',
      }}
    >
      <div>
        <SurveysChartContainer 
          startDate={`${start}`} 
          endDate={`${end}`} 
          location={'top'} 
        />
      </div>
      <div style={{ marginTop: 25 }}>
        <SleepChartSection startDate={start} endDate={end} />
      </div>
      <div>
        {/* Stress Chart */}
        <StressChart start={`${startDate}`} end={`${endDate}`} />
      </div>
      <div>
        {/* Mood Chart */}
        <MoodChart start={`${startDate}`} end={`${endDate}`} />
      </div>
      <div>
        {/* Heart Rate Varialibity Chart */}
        <VitalsContainer startDate={`${startDate}`} endDate={`${endDate}`} location={'tinnitus'} />
      </div>
      <div>
        <SurveysChartContainer 
          startDate={`${start}`} 
          endDate={`${end}`} 
          location={"bottom"} 
        />
        </div>
    </div>
  );
};

export default ChartsPage;
