import React from 'react';
import { getPatientTimezone } from 'contexts/PatientContext';
import { curveLinear } from 'd3-shape';
import { QuestionnaireInfo } from 'graphql/codegen/schemas';
import moment from 'moment-timezone';
import { Area, AreaChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { CHARTS_SIZES, GRAPH_REVERSE_TOOLTIP_COORDINATE } from '../../../../utils/constants';
import { Tooltip as InfoTooltip } from '../../tooltip';
import { ChartToolTip } from '../chart-tooltip';
import styles from '../chart.module.scss';
import { getTicksDD, getTicksH } from '../utlity';

type Props = {
  title: string;
  start: string;
  end: string;
  data: { ts: number; value: number }[];
  survey?: QuestionnaireInfo;
  showXAxis?: boolean;
  location?: string
};
const SurveyChart = ({ title, start, end, data, survey, showXAxis, location }: Props) => {
  const GRAPH_WIDTH_PX = CHARTS_SIZES.CDP_WIDTH;
  const GRAPH_HEIGHT_PX = showXAxis ? CHARTS_SIZES.UR_HEIGHT : CHARTS_SIZES.CDP_HEIGHT;
  const patientTimezone = getPatientTimezone();

  const customToolTip = ({
    active,
    coordinate,
    payload,
    label,
  }: {
    active: boolean;
    coordinate: { x: number; y: number };
    label: number;
    payload: Array<any>;
  }): JSX.Element | null => {
    if (!active) {
      return null;
    }
    const reverse = coordinate.x > GRAPH_REVERSE_TOOLTIP_COORDINATE;
    let yValue = payload ? payload[0]?.payload?.value : 'Unable to retrieve value';
    return <ChartToolTip date={label} title={`${title}`} value={yValue} reverse={reverse} />;
  };

  const YAxisFormatter = ({
    x,
    y,
    payload,
  }: {
    x: number;
    y: number;
    payload: { coordinate: number; value: number; offset: number };
  }): JSX.Element => {
    const { value } = payload;
    if (!y || !x) {
      return <text />;
    }
    return (
      <text
        fontSize='10'
        x={x - 7}
        y={y + 4}
        textAnchor='end'
        color='#444444'
        fontWeight='normal'
        fontFamily='sans-serif'
      >
        {value}
      </text>
    );
  };

  return (
    <div className={styles.chartContainer}>
      <div className={styles.chartHeader}>
        <span className={styles.chartTitle}>{title}</span>
        {survey?.description && (
          <InfoTooltip portal baseStyles={`${styles.infoTooltip} ${styles.events}`} type='left' background='#F5F6FA'>
            <div dangerouslySetInnerHTML={{ __html: survey?.description }} />
          </InfoTooltip>
        )}
      </div>
      <AreaChart width={GRAPH_WIDTH_PX} height={GRAPH_HEIGHT_PX} data={data}>
        <defs>
          <linearGradient id='d1' x1={0} y1={0} x2={0} y2={1}>
            <stop offset='3%' stopColor='#417EB9' stopOpacity={0.8} />
            <stop offset='97%' stopColor='rgba(65, 126, 185, 0.2)' stopOpacity={0} />
          </linearGradient>
        </defs>

        <XAxis
          xAxisId={0}
          dy={-10}
          dx={2}
          tickLine={true}
          tickSize={16}
          tickFormatter={(unixTime): string => moment.utc(unixTime).tz(patientTimezone).format('hA')}
          dataKey='ts'
          domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
          allowDataOverflow={true}
          type='number'
          scale='time'
          textAnchor='start'
          ticks={getTicksH(start, end, GRAPH_WIDTH_PX)}
          mintickgap={0}
          interval={0}
          hide={!showXAxis}
        />
        <XAxis
          xAxisId={2}
          dy={-18}
          dx={0}
          style={{
            fontSize: '11',
            fontWeight: 'bold',
            fontFamily: 'sans-serif',
            color: '#000000',
          }}
          tickLine={false}
          axisLine={false}
          tickFormatter={(unixTime): string => moment.utc(unixTime).format('ddd')}
          dataKey='ts'
          domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
          allowDataOverflow={true}
          type='number'
          scale='time'
          textAnchor='start'
          ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
          mintickgap={0}
          interval={0}
          hide={!showXAxis}
        />

        <XAxis
          xAxisId={3}
          dy={0}
          dx={0}
          tickLine={false}
          axisLine={false}
          tickFormatter={(unixTime): string => moment.utc(unixTime).format('M/D')}
          dataKey='ts'
          domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
          allowDataOverflow={true}
          type='number'
          scale='time'
          textAnchor='start'
          ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
          mintickgap={0}
          interval={1}
          hide={location ? false : !showXAxis}
        />
        <YAxis dataKey='value' interval={0} tickSize={0} tick={YAxisFormatter} />
        <CartesianGrid stroke='#A3A6B3' />
        <Tooltip content={customToolTip} allowEscapeViewBox={{ x: false, y: true }} />
        <Area type={curveLinear} dataKey='value' stroke='#417EB9' fill='url(#d1)' strokeWidth='2' />
      </AreaChart>
    </div>
  );
};

export default SurveyChart;
