import React, { useState, useEffect, useContext, FC } from 'react';
import { AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area, ReferenceLine, BarChart, Bar } from 'recharts';
import moment from 'moment-timezone';
import { curveLinear } from 'd3-shape';
import { useStepsQuery, useStepsAvgQuery } from 'graphql/codegen/hooks';
import styles from '../chart.module.scss';
import { getPatientTimezone, PatientContext } from '../../../../contexts/PatientContext';
import { getTicksH, getTicksDD } from '../utlity';
import { getActivityScore, getExerciseScores } from '../../../../services/api/chart';
import { GRAPH_REVERSE_TOOLTIP_COORDINATE, CHARTS_SIZES } from '../../../../utils/constants';
import { Tooltip as InfoTooltip } from '../../tooltip';
import InfoBox from './info-box';
import StepsTooltip from './steps-tooltip';
import { StepsQueryVariables } from '../../../../graphql/codegen/operations';
import { da } from 'date-fns/locale';
import { nextDay } from 'date-fns';

type ActivityChartDto = {
  ts: number;
  count: number;
  start?: number;
};

type Props = {
  start: string;
  end: string;
  urData?: { ts: string; value: number }[];
  isPdf?: boolean;
  reference?: number;
  patient?: any;
};

export const StepsChart: FC<Props> = ({ start, end, urData, isPdf, reference, patient }): JSX.Element => {
  const patientTimezone = getPatientTimezone();
  const [data, setData] = useState<Array<ActivityChartDto>>([]);
  const GRAPH_WIDTH_PX = urData ? CHARTS_SIZES.UR_WIDTH : CHARTS_SIZES.CDP_WIDTH;
  const GRAPH_HEIGHT_PX = urData ? CHARTS_SIZES.UR_HEIGHT : CHARTS_SIZES.CDP_HEIGHT;
  const [dates, setDates] = useState<any[]>([]);
  const [showXAxisDays, setShowXAxisDays] = useState<boolean>(true);
  const [Yaxis, setYaxis] = useState<number>(0);
  let { data: dailyData } = useStepsQuery({
    variables: {
      id: patient?.id,
      start: start,
      end: end,
      tz: patientTimezone,
      maxPoints: 270,
    },
  });

  const differenceInTime = new Date(end).getTime() - new Date(start).getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  const getXrayView = () => {
    if (differenceInDays > 7) {
      setShowXAxisDays(false);
    } else {
      setShowXAxisDays(true);
    }
  };

  const parseStepCount = async () => {
    const parsedData =
      dailyData?.user_steps?.map?.(entry => {
        return {
          count: entry?.count!,
          ts: moment.utc(entry?.ts).tz(patientTimezone).unix() * 1000,
        };
      }) || [];
    setData(parsedData);
  };

  const getDates = () => {
    let dateArray: Number[] = [];
    data.map(entry => {
      const currentDate = new Date(entry.ts).getDate();
      if (!dateArray.includes(currentDate)) {
        dateArray.push(currentDate);
      }
    });
    totalStepCount(dateArray);
  };

  const totalStepCount = dateArray => {
    let newArray: any[] = [];
    let info = { count: 0, ts: 0, start: 0 };
    let totalSteps = 0;
    let position = 0;
    for (let date of dateArray) {
      data.map((entry, index) => {
        const currentDate = new Date(entry.ts).getDate();
        if (currentDate === date) {
          totalSteps += entry.count;
          position = index;
        }
      });
      info = {
        count: totalSteps,
        ts: moment.utc(data[position].ts).tz(patientTimezone).unix() * 1000,
        start: moment.utc(data[position].ts).tz(patientTimezone).startOf('day').unix() * 1000,
      };
      newArray.push(info);
      totalSteps = 0;
      info = {
        count: 0,
        ts: 0,
        start: 0,
      };
    }
    setDates([...newArray]);
  };

  useEffect(() => {
    parseStepCount();
    getXrayView();
    if (data.length > 0) {
      getDates();
    }
  }, [dailyData, data.length]);

  const YAxisFormatter = ({
    x,
    y,
    payload,
  }: {
    x: number;
    y: number;
    payload: { coordinate: number; value: number; offset: number };
  }): JSX.Element => {
    const { value } = payload;
    if (!y || !x) {
      return <text />;
    }
    if (value === 0 && !urData) {
      return (
        <text
          fontSize='10'
          x={x - 10}
          y={y}
          textAnchor='start'
          color='#444444'
          fontWeight='normal'
          fontFamily='sans-serif'
        >
          {value}
        </text>
      );
    }
    if (value && !urData) {
      return (
        <text
          fontSize='10'
          x={value >= 1000 ? x - 16 : x - 22}
          y={y + 4}
          textAnchor='start'
          color='#444444'
          fontWeight='normal'
          fontFamily='sans-serif'
        >
          {value >= 1000 ? `${Math.round(value / 1000)}k` : value}
        </text>
      );
    }
    return (
      <text
        fontSize='10'
        x={x - 7}
        y={y + 4}
        textAnchor='end'
        color='#444444'
        fontWeight='normal'
        fontFamily='sans-serif'
      ></text>
    );
  };

  const customToolTip = ({
    active,
    coordinate,
    payload,
    label,
  }: {
    active: boolean;
    coordinate: { x: number; y: number };
    label: number;
    payload: Array<any>;
  }): JSX.Element | null => {
    if (!payload?.[0]?.payload && !active) {
      return null;
    }
    const reverse = coordinate.x > GRAPH_REVERSE_TOOLTIP_COORDINATE;
    const value = payload?.[0]?.payload ?? {};
    const time = showXAxisDays
      ? value?.ts
      : moment.utc(value?.ts).tz(patientTimezone).subtract(1, 'minute').toLocaleString();
    return <StepsTooltip date={time} value={{ count: value.count }} reverse={reverse} />;
  };

  return (
    <div id={`exerciseChart${isPdf ? '__pdf' : ''}`} style={isPdf ? { display: 'none' } : {}}>
      <div className={styles.chartHeader}>
        <span className={styles.chartTitle}>STEPS</span>
        {/* {!urData && (
          <InfoTooltip baseStyles={`${styles.infoTooltip} ${styles.activity}`} type='left' background='#F5F6FA'>
            <InfoBox />
          </InfoTooltip>
        )} */}
      </div>
      <BarChart
        width={isPdf ? CHARTS_SIZES.UR_PDF_WIDTH : GRAPH_WIDTH_PX}
        height={isPdf ? CHARTS_SIZES.UR_PDF_HEIGHT : GRAPH_HEIGHT_PX + 60}
        data={showXAxisDays ? data : dates}
      >
        <defs>
          <linearGradient id='d1' x1={0} y1={0} x2={0} y2={1}>
            <stop offset='3%' stopColor='#417EB9' stopOpacity={0.8} />
            <stop offset='97%' stopColor='rgba(65, 126, 185, 0.2)' stopOpacity={0} />
          </linearGradient>
          {!urData && (
            <linearGradient id='d1' x1={0} y1={0} x2={0} y2={1}>
              <stop offset='3%' stopColor='#417EB9' stopOpacity={0.8} />
              <stop offset='97%' stopColor='rgba(65, 126, 185, 0.2)' stopOpacity={0} />
            </linearGradient>
          )}
        </defs>
        {!urData && (
          <XAxis
            xAxisId={0}
            dx={0}
            dy={-10}
            style={{ fontSize: '9', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={false}
            tickSize={16}
            tickFormatter={(unixTime): string =>
              !showXAxisDays
                ? moment.utc(unixTime).tz(patientTimezone).format(' ')
                : moment.utc(unixTime).tz(patientTimezone).format('hA')
            }
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='auto'
            textAnchor='start'
            ticks={getTicksH(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        <XAxis
          xAxisId={2}
          dy={!showXAxisDays ? -25 : -15}
          dx={showXAxisDays ? 0 : -5}
          style={{
            fontSize: '11',
            fontWeight: 'bold',
            fontFamily: 'sans-serif',
            color: '#000000',
          }}
          tickLine={false}
          axisLine={false}
          tickFormatter={(unixTime): string => moment.utc(unixTime).tz(patientTimezone).format('ddd')}
          dataKey='ts'
          domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
          allowDataOverflow={true}
          type='number'
          scale='time'
          textAnchor='start'
          ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
          mintickgap={0}
          interval={0}
        />
        <XAxis
          xAxisId={3}
          dy={!showXAxisDays ? -40 : -32.5}
          dx={showXAxisDays ? 0 : -5}
          style={{ fontSize: '11', fontFamily: 'sans-serif', color: '#000000' }}
          tickLine={false}
          axisLine={false}
          tickFormatter={(unixTime): string => moment.utc(unixTime).tz(patientTimezone).format('M/D')}
          dataKey='ts'
          domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
          allowDataOverflow={true}
          type='number'
          scale='time'
          textAnchor='start'
          ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
          mintickgap={0}
          interval={0}
        />
        {/* {!!urData && reference && <ReferenceLine y={reference} stroke='green' strokeWidth={1.2} />} */}
        <YAxis
          allowDataOverflow={true}
          tick={YAxisFormatter}
          interval={0}
          tickSize={0}
          //ticks={differenceInDays > 7 ? [8000, 6400, 4800, 3200, 1600, 0] : [2000, 1500, 1000, 500, 0]}
          //domain={differenceInDays > 7 ? [0, 8000] : [0, 3999]}
        />
        <CartesianGrid stroke='#A3A6B3' />
        {!urData && <Tooltip content={customToolTip} allowEscapeViewBox={{ x: false, y: true }} />}
        <Bar dataKey='count' fill='url(#d1)' strokeWidth='2' />
        {/* {!urData && <Bar dataKey='count' stroke='#417EB9' fill='url(#d2)' strokeWidth='2'   />} */}
      </BarChart>
    </div>
  );
};
